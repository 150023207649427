@font-face {
    font-family: 'Nunito-Black';
    src: url('Nunito-Black.eot');
    src: url('Nunito-Black.eot?#iefix') format('embedded-opentype'),
        url('Nunito-Black.woff') format('woff'),
        url('Nunito-Black.ttf') format('truetype'),
        url('Nunito-Black.svg#Nunito-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito-Bold';
    src: url('Nunito-Bold.eot');
    src: url('Nunito-Bold.eot?#iefix') format('embedded-opentype'),
        url('Nunito-Bold.woff') format('woff'),
        url('Nunito-Bold.ttf') format('truetype'),
        url('Nunito-Bold.svg#Nunito-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito-ExtraBold';
    src: url('Nunito-ExtraBold.eot');
    src: url('Nunito-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Nunito-ExtraBold.woff') format('woff'),
        url('Nunito-ExtraBold.ttf') format('truetype'),
        url('Nunito-ExtraBold.svg#Nunito-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito-Light';
    src: url('Nunito-Light.eot');
    src: url('Nunito-Light.eot?#iefix') format('embedded-opentype'),
        url('Nunito-Light.woff') format('woff'),
        url('Nunito-Light.ttf') format('truetype'),
        url('Nunito-Light.svg#Nunito-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito-ExtraLight';
    src: url('Nunito-ExtraLight.eot');
    src: url('Nunito-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Nunito-ExtraLight.woff') format('woff'),
        url('Nunito-ExtraLight.ttf') format('truetype'),
        url('Nunito-ExtraLight.svg#Nunito-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito-Regular';
    src: url('Nunito-Regular.eot');
    src: url('Nunito-Regular.eot?#iefix') format('embedded-opentype'),
        url('Nunito-Regular.woff') format('woff'),
        url('Nunito-Regular.ttf') format('truetype'),
        url('Nunito-Regular.svg#Nunito-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito-SemiBold';
    src: url('Nunito-SemiBold.eot');
    src: url('Nunito-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Nunito-SemiBold.woff') format('woff'),
        url('Nunito-SemiBold.ttf') format('truetype'),
        url('Nunito-SemiBold.svg#Nunito-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

